import { get, child, ref } from 'firebase/database'
// Services
import { database } from '../services/firebase'
import environment from './environment'

enum EventStatus {
  NONE = 0,
  VIP = 'vip',
  UNITE = 'unite',
}

export const getUserEventStatus = async (id: number) =>
  await get(child(ref(database), `guests/${id}`))
    .then(snapshot => {
      if (snapshot.exists()) {
        return snapshot.val() ? EventStatus.VIP : EventStatus.UNITE
      } else {
        return EventStatus.NONE
      }
    })
    .catch(error => {
      console.error(error)
      return EventStatus.NONE
    })

export async function handleEventModalLogic(id: number, callback: () => void) {
  const { IS_EVENT_SITE: isEvent, IS_VIP_SITE: isVip } = environment
  if (!isEvent || !isVip) return
  if (!id) return

  const status = await getUserEventStatus(id)
  console.log(
    '🚀 ~ file: eventSiteHelpers.ts ~ line 32 ~ handleEventModalLogic ~ status',
    status
  )

  switch (status) {
    case 'vip':
      console.log('vip')
      return

    case 'unite':
      console.log('unite')
      if (!isEvent) {
        return callback()
      }

    default:
      console.log('no status')
      return callback()
  }
}
